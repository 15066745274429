
import { IonContent, IonPage } from "@ionic/vue";
import MaxahDetailedFooter from '@/components/DetailedFooter.vue';

export default {
  name: "Portfolio",
  components: {
    IonContent, IonPage, MaxahDetailedFooter
  },
  data() {
    return {
      
    };
  },
};
